import React from 'react';
import Navbar from '../components/Navbar';
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { useState, useRef, useEffect } from 'react';

// main layout
export default function Layout({ children, location }) {

    const [state, setState] = useState(true);
    // child currently being rendered
    // we save a copy of the old child when we transition 
    // then replace at the middle of the transition
    // const [stylePrefix, setStylePrefix] = useState("none");
    const [child, setChild] = useState(null);
    const nodeRef = useRef(null);

    // useEffect(() => {
    //     //prevent transition if we are going to the same page
    //     // console.log(child);
    //     // console.log(location);
    //     if (child === null || child.key !== location.pathname) {
    //         setState((state) => !state)
    //     }
    // }, [children, child, location.pathname])

    return (
        <div className='h-screen flex' style={{ "flexFlow": "column" }}>
            <Navbar />
            <div className='transition-all flex-auto flex' style={{ "flexFlow": "column" }}>
                {/* <SwitchTransition mode="out-in">
                    <CSSTransition
                        key={state}
                        nodeRef={nodeRef}
                        addEndListener={(done) => {
                            nodeRef.current.addEventListener("transitionend", done, false);
                        }}
                        classNames={stylePrefix}
                        onEnter={() => setChild(React.cloneElement(children, { state }))}
                        onEntered={() => { setChild(children); setStylePrefix("fade") }}
                    > */}
                <div className='h-auto flex-auto' ref={nodeRef}>
                    {child || children}
                </div>
                {/* </CSSTransition>
                </SwitchTransition> */}
            </div>
        </div>
    )
}
