import React from 'react'
import { Link } from 'gatsby';
import { StaticImage } from "gatsby-plugin-image"
import NavButton from './NavButton';
export default function Navbar() {

    return (
        <nav className='container flex justify-between p-6 pt-16 mx-auto items-center text-sm flex-wrap xs:flex-nowrap'>
            <Link to="/" className="w-full md:w-auto collapse hidden md:block md:visible">
                <StaticImage
                    formats={["auto", "webp", "avif"]}
                    src="../assets/images/logo.png"
                    width={814 / 2}
                    height={84 / 2}
                    loading='eager'
                    quality={95}
                    imgStyle={{ transition: 'none' }}
                    alt="Logo"
                />
            </Link>
            <Link to="/" className="w-full visible block md:hidden md:collapse">
                <StaticImage
                    formats={["auto", "webp", "avif"]}
                    src="../assets/images/logo.png"
                    width={814}
                    height={84}
                    loading='eager'
                    quality={95}
                    imgStyle={{ transition: 'none' }}
                    alt="Logo"
                />
            </Link>
            {/* <div className="basis-full md:collapse mt-3"></div> */}
            <NavButton text="Design Work" link="/projects"></NavButton>
            <span className='mx-0 lg:mx-20 xl:mx-48'>+</span>
            <NavButton text="About" link="/about"></NavButton>
            <NavButton text="Contact" link="/contact"></NavButton>
        </nav>
    )
}
