import React from 'react'
import { Link } from 'gatsby';

export default function NavButton({ text, link }) {
    return (
        <Link to={link} className='border border-black py-1 px-2 pb-1.5 m-2 hover:border-gray-300 no-underline'>
            <span >{text}</span>
        </Link>
    )
}
